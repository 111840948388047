
import { Component, Vue } from "vue-property-decorator";
import i18n from "../../plugins/i18n";
import { Watch } from "vue-property-decorator";

import AgeGenderGroupService from "../../services/sporteventCalculation/AgeGenderGroupService";
import AgeGenderGroup from "../../models/sporteventCalculation/AgeGenderGroup";

@Component({})
export default class AgeGenderGroupsComponent extends Vue {
  // Loading
  public loading = true;
  // Table
  public items: AgeGenderGroup[] = [];
  public fields = [
    {
      key: "gender",
      sortable: true,
      label: i18n.tc("societymgmt.gender"),
    },
    {
      key: "ageFrom",
      sortable: true,
      label: i18n.tc("societymgmt.ageFrom"),
    },
    {
      key: "ageTo",
      sortable: true,
      label: i18n.tc("societymgmt.ageTo"),
    },
    {
      key: "actions",
      sortable: false,
      label: "",
    },
  ];
  public filter = "";
  public perPage = 10;
  public pageOptions = [5, 10, 20, 30];
  public totalRows = 1;
  public currentPage = 1;

  getAll(): void {
    AgeGenderGroupService.getAll().then((items: AgeGenderGroup[]) => {
      this.items = items;
      if (this.items) {
        this.totalRows = this.items.length;
      }
      this.loading = false;
    });
  }
  @Watch("$route", { immediate: true, deep: true })
  onUrlChange(): void {
    this.getAll();
  }
}
